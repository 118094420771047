import React, { useState, useEffect, useCallback } from 'react';

const Config = () => {
  const [repositories, setRepositories] = useState([]);
  const [projects, setProjects] = useState([]);
  const [ap, setAp] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isGitHubAuthenticated, setIsGitHubAuthenticated] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [isSyncing, setIsSyncing] = useState(false);
  const [integrations, setIntegrations] = useState([]);
  const [updatingRepos, setUpdatingRepos] = useState(new Set());
  const [organizations, setOrganizations] = useState([]);
  const [showOrgSelector, setShowOrgSelector] = useState(false);

  useEffect(() => {
    const initializeAP = async () => {
      if (window.AP) {
        try {
          await new Promise((resolve) => window.AP.context.getContext(resolve));
          setAp(window.AP);
          setIsLoading(false);
        } catch (error) {
          console.error('Error initializing AP:', error);
          setError('Error initializing Atlassian Connect. Please refresh the page.');
        }
      } else {
        console.error('AP is not available');
        setError('Atlassian Connect is not available. Please check your installation.');
      }
    };

    initializeAP();
  }, []);

  useEffect(() => {
    if (ap) {
      fetchProjects();
      fetchRepositories();
      fetchRepositoryIntegrations();
      // Check URL parameters for org selector flag
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('github_auth_success') === 'true' && urlParams.get('show_org_selector') === 'true') {
        // Fetch and show organizations
        fetchOrganizations();
      }
    }
  }, [ap]);

  const fetchProjects = async () => {
    try {
      const response = await ap.request('/rest/api/3/project');
      const projectsData = JSON.parse(response.body);
      setProjects(projectsData.map(project => ({
        id: project.id,
        name: `${project.name} (${project.key})`
      })));
    } catch (error) {
      console.error('Error fetching projects:', error);
      setError('Error loading projects');
    }
  };

  const getBaseUrl = (ap) => {
    try {
      // First try to get from ap.flagsUrl
      if (ap?.flagsUrl) {
        return new URL(ap.flagsUrl).origin;
      }
      
      // Fallback to getting from window location
      if (window?.location?.href) {
        const currentUrl = new URL(window.location.href);
        // If we're on Atlassian domain, use the iframeUrl
        if (currentUrl.hostname.includes('atlassian.net')) {
          return ap?.iframeUrl ? new URL(ap.iframeUrl).origin : process.env.BASE_URL;
        }
        return currentUrl.origin;
      }
      
      // Final fallback to environment variable
      return process.env.BASE_URL;
    } catch (error) {
      console.error('Error getting base URL:', error);
      // Fallback to environment variable 
      return process.env.BASE_URL;
    }
  };

  const fetchRepositories = async () => {
    try {
      const token = await ap.context.getToken();
      const baseUrl = getBaseUrl(ap);
      
      if (!baseUrl) {
        throw new Error('Could not determine base URL');
      }

      const response = await fetch(`${baseUrl}/get-github-repos`, {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${token}`,
          'Accept': 'application/json'
        },
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      console.log('response', JSON.stringify(response));
      const result = await response.json();
      if (result && Array.isArray(result.repositories)) {
        setRepositories(result.repositories.map(repo => ({
          ...repo,
          isEditing: false,
          selectedProject: repo.projectId
        })));
        // setIsGitHubAuthenticated(true);
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching repositories:', error);
      setError('Error loading repositories');
      setIsGitHubAuthenticated(false);
    }
  };

  const fetchRepositoryIntegrations = async () => {
    try {
      const token = await ap.context.getToken();
      const baseUrl = getBaseUrl(ap);
      
      if (!baseUrl) {
        throw new Error('Could not determine base URL');
      }

      const response = await fetch(`${baseUrl}/get-repository-integrations`, {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${token}`,
          'Accept': 'application/json'
        },
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const result = await response.json();
      if (result && Array.isArray(result.integrations)) {
        setIntegrations(result.integrations);
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching repository integrations:', error);
      setError('Error loading repository integrations');
    }
  };

  const handleProjectChange = (repoId, projectId) => {
    setRepositories(repositories.map(repo =>
      repo.id === repoId ? { ...repo, selectedProject: projectId } : repo
    ));
  };

  const handleSave = async (repo) => {
    try {      
        console.log('Saving repository-project mapping for repo:', repo);
        
        const token = await ap.context.getToken();
        const baseUrl = getBaseUrl(ap);

        if (!baseUrl) {
          throw new Error('Could not determine base URL');
        }

        const response = await fetch(`${baseUrl}/save-repo-project-mapping`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${token}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                selectedProject: repo.selectedProject, 
                createdAt: repo.createdAt, 
                id: repo.id, 
                integrationId: repo.integrationId, 
                isEditing: repo.isEditing, 
                isPrivate: repo.isPrivate, 
                name: repo.name, 
                owner: repo.owner, 
                type: repo.type
            })
        });

        if (response.status !== 200) {
            throw new Error('Failed to save repository-project mapping');
        }

        console.log('Repository-project mapping saved successfully');
        setRepositories(repositories.map(r =>
            r.id === repo.id ? { ...r, isEditing: false } : r
        ));
    } catch (error) {
      console.error('Error saving repository-project mapping:', error);
      setError('Error saving repository-project mapping');
    }
  };

  const handleEdit = (repoId) => {
    setRepositories(repositories.map(repo =>
      repo.id === repoId ? { ...repo, isEditing: true } : repo
    ));
  };

  const handleGitHubAuth = async () => {
    try {
      console.log('Starting GitHub authentication');
      
      const token = await ap.context.getToken();
      const baseUrl = getBaseUrl(ap);
      
      if (!baseUrl) {
        throw new Error('Could not determine base URL');
      }

      const response = await fetch(`${baseUrl}/start-github-auth`, {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });

      if (response.status !== 200) {
        throw new Error('Failed to start GitHub authentication');
      }

      const data = await response.json();

      console.log('GitHub authentication data:', data);

      if (data.authUrl) {
        window.open(data.authUrl, '_parent');
      } else {
        throw new Error('No auth URL received');
      }

      console.log('GitHub authentication started successfully');
    } catch (error) {
      console.error('Error starting GitHub auth:', error);
      setError('Failed to start GitHub authentication');
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedRepositories = React.useMemo(() => {
    let sortableItems = [...repositories];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [repositories, sortConfig]);

  const handleUpdateIndex = async (repoId) => {
    if (updatingRepos.has(repoId)) return;

    try {
      setRepositories(prev => prev.map(repo => 
        repo.id === repoId 
          ? { 
              ...repo, 
              manifestGenerationStatus: 'in_progress',
              manifestGenerationMessage: 'Starting manifest generation...' 
            } 
          : repo
      ));
      
      setUpdatingRepos(prev => new Set([...prev, repoId]));
      
      const token = await ap.context.getToken();
      const baseUrl = getBaseUrl(ap);
      
      if (!baseUrl) {
        throw new Error('Could not determine base URL');
      }

      const response = await fetch(`${baseUrl}/update-repo-index/${repoId}`, {
        method: 'POST',
        headers: {
          'Authorization': `JWT ${token}`,
          'Accept': 'application/json'
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      let pollCount = 0;
      const maxPolls = 30; // Maximum number of polling attempts (60 seconds)
      
      const pollStatus = async () => {
        if (pollCount >= maxPolls) {
          setUpdatingRepos(prev => {
            const next = new Set(prev);
            next.delete(repoId);
            return next;
          });
          setRepositories(prev => prev.map(repo => 
            repo.id === repoId 
              ? { 
                  ...repo, 
                  manifestGenerationStatus: 'error',
                  manifestGenerationMessage: 'Timeout waiting for update' 
                } 
              : repo
          ));
          return;
        }

        try {
          const statusResponse = await fetch(`${baseUrl}/get-repo-status/${repoId}`, {
            headers: {
              'Authorization': `JWT ${token}`,
              'Accept': 'application/json'
            },
            credentials: 'include'
          });

          if (!statusResponse.ok) throw new Error('Failed to fetch status');
          
          const statusData = await statusResponse.json();
          
          setRepositories(prev => prev.map(repo => 
            repo.id === repoId 
              ? { 
                  ...repo, 
                  manifestGenerationStatus: statusData.manifestGenerationStatus,
                  manifestGenerationMessage: statusData.manifestGenerationMessage 
                } 
              : repo
          ));

          if (statusData.manifestGenerationStatus === 'in_progress') {
            pollCount++;
            setTimeout(pollStatus, 2000);
          } else {
            setUpdatingRepos(prev => {
              const next = new Set(prev);
              next.delete(repoId);
              return next;
            });
          }
        } catch (error) {
          console.error('Error polling status:', error);
          setUpdatingRepos(prev => {
            const next = new Set(prev);
            next.delete(repoId);
            return next;
          });
        }
      };

      pollStatus();

    } catch (error) {
      console.error('Error updating index:', error);
      setRepositories(prev => prev.map(repo => 
        repo.id === repoId 
          ? { 
              ...repo, 
              manifestGenerationStatus: 'error',
              manifestGenerationMessage: 'Failed to start index update' 
            } 
          : repo
      ));
      setUpdatingRepos(prev => {
        const next = new Set(prev);
        next.delete(repoId);
        return next;
      });
    }
  };

  const getStatusBadgeStyle = (status) => {
    const baseStyle = {
      padding: '4px 8px',
      borderRadius: '12px',
      fontSize: '12px',
      fontWeight: 'bold',
    };

    switch (status) {
      case 'completed':
        return { ...baseStyle, backgroundColor: '#00875A', color: 'white' };
      case 'error':
        return { ...baseStyle, backgroundColor: '#DE350B', color: 'white' };
      case 'in_progress':
        return { ...baseStyle, backgroundColor: '#0052CC', color: 'white' };
      case 'pending':
        return { ...baseStyle, backgroundColor: '#0052CC', color: 'white' };
      default:
        return { ...baseStyle, backgroundColor: '#6B778C', color: 'white' };
    }
  };

  const RefreshIcon = ({ isSpinning, onClick }) => (
    <div 
      onClick={onClick}
      style={{ 
        padding: '4px',
        borderRadius: '50%',
        backgroundColor: isSpinning ? '#DEEBFF' : 'transparent',
        transition: 'all 0.2s ease',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <svg 
        width="16" 
        height="16" 
        viewBox="0 0 24 24" 
        fill="currentColor"
        style={{ 
          transform: isSpinning ? 'rotate(0deg)' : 'none',
          animation: isSpinning ? 'spin 1s linear infinite' : 'none',
        }}
      >
        <path d="M17.65 6.35A7.958 7.958 0 0012 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08A5.99 5.99 0 0112 18c-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/>
      </svg>
    </div>
  );

  const renderRepositoriesTable = () => (
    <table style={styles.table}>
      <thead>
        <tr>
          <th style={styles.th} onClick={() => handleSort('owner')}>Owner</th>
          <th style={styles.th} onClick={() => handleSort('name')}>Name</th>
          <th style={styles.th} onClick={() => handleSort('url')}>URL</th>
          <th style={styles.th} onClick={() => handleSort('selectedProject')}>Project</th>
          <th style={styles.th}>Actions</th>
          <th style={styles.th} onClick={() => handleSort('manifestGenerationStatus')}>Indexing Status</th>
        </tr>
      </thead>
      <tbody>
        {sortedRepositories.map(repo => (
          <tr key={repo.id}>
            <td style={styles.td}>{repo.owner}</td>
            <td style={styles.td}>{repo.name}</td>
            <td style={styles.td}>{repo.url}</td>
            <td style={styles.td}>
              {repo.isEditing ? (
                <select
                  value={repo.selectedProject || ''}
                  onChange={(e) => handleProjectChange(repo.id, e.target.value)}
                  style={styles.select}
                >
                  <option value="">Select Project</option>
                  {projects.map(project => (
                    <option key={project.id} value={project.id}>{project.name}</option>
                  ))}
                </select>
              ) : (
                projects.find(p => p.id === repo.selectedProject)?.name || 'Not set'
              )}
            </td>
            <td style={styles.td}>
              {repo.isEditing ? (
                <button onClick={() => handleSave(repo)} style={styles.button}>Save</button>
              ) : (
                <button onClick={() => handleEdit(repo.id)} style={styles.button}>Edit</button>
              )}
            </td>
            <td style={styles.td}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <div style={getStatusBadgeStyle(repo.manifestGenerationStatus)}>
                  {repo.manifestGenerationStatus || 'unknown'}
                </div>
                <div 
                  style={{ 
                    color: updatingRepos.has(repo.id) ? '#0065FF' : '#0052CC',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  title="Update Index"
                >
                  <RefreshIcon 
                    isSpinning={updatingRepos.has(repo.id)}
                    onClick={() => handleUpdateIndex(repo.id)}
                  />
                </div>
              </div>
              {repo.manifestGenerationMessage && (
                <div style={{ fontSize: '12px', color: '#6B778C', marginTop: '4px' }}>
                  {repo.manifestGenerationMessage}
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const handleSyncRepositories = async (integrationId) => {
    try {
      setIsSyncing(true);
      const token = await ap.context.getToken();
      const baseUrl = getBaseUrl(ap);
      
      if (!baseUrl) {
        throw new Error('Could not determine base URL');
      }

      const response = await fetch(`${baseUrl}/sync-repositories/${integrationId}`, {
        method: 'POST',
        headers: {
          'Authorization': `JWT ${token}`,
          'Accept': 'application/json'
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log('Repository sync result:', result);
      
      // Refresh the repositories list after syncing
      await fetchRepositories();

      ap.flag.success({
        title: 'Repositories Synchronized',
        body: `Successfully synced ${result.syncedRepositories} repositories.`
      });
    } catch (error) {
      console.error('Error syncing repositories:', error);
      ap.flag.error({
        title: 'Sync Failed',
        body: 'Failed to synchronize repositories. Please try again.'
      });
    } finally {
      setIsSyncing(false);
    }
  };

  const renderIntegrationsTable = () => (
    <table style={styles.table}>
      <thead>
        <tr>
          <th style={styles.th}>System</th>
          <th style={styles.th}>URL</th>
          <th style={styles.th}>Owner</th>
          <th style={styles.th}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {integrations.map(integration => (
          <tr key={integration.id}>
            <td style={styles.td}>{integration.system}</td>
            <td style={styles.td}>{integration.url}</td>
            <td style={styles.td}>{integration.primaryIntegration?.login || 'N/A'}</td>
            <td style={styles.td}>
              <button onClick={() => handleSyncRepositories(integration.id)} style={styles.button}>
                Sync Repositories
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const handleOrgSelection = async (orgId) => {
    try {
      const token = await ap.context.getToken();
      const baseUrl = getBaseUrl(ap);
      const response = await fetch(`${baseUrl}/select-github-org`, {
        method: 'POST',
        headers: {
          'Authorization': `JWT ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orgId })
      });

      if (!response.ok) {
        throw new Error('Failed to select organization');
      }

      // Refresh the integrations list
      await fetchRepositoryIntegrations();
      setShowOrgSelector(false);
    } catch (error) {
      console.error('Error selecting organization:', error);
      setError('Failed to select organization');
    }
  };

  const renderOrgSelector = () => (
    showOrgSelector && (
      <div style={styles.orgSelector}>
        <h3>Select GitHub Organization</h3>
        <div style={styles.orgList}>
          {organizations.map(org => (
            <button
              key={org.id}
              onClick={() => handleOrgSelection(org.id)}
              style={styles.orgButton}
            >
              {org.login}
            </button>
          ))}
        </div>
      </div>
    )
  );

  const fetchOrganizations = async () => {
    try {
      const token = await ap.context.getToken();
      const baseUrl = getBaseUrl(ap);
      const response = await fetch(`${baseUrl}/get-github-orgs`, {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${token}`,
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch organizations');
      }

      const data = await response.json();
      setOrganizations(data.organizations);
      setShowOrgSelector(true);
    } catch (error) {
      console.error('Error fetching organizations:', error);
      setError('Failed to fetch organizations');
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Amulent CodeMerlin Configuration</h1>
      <div style={styles.githubAuth}>
        {!isGitHubAuthenticated ? (
          <button onClick={handleGitHubAuth} style={styles.button}>Authenticate with GitHub</button>
        ) : (
          <>
            <p>GitHub authenticated</p>
          </>
        )}
      </div>
      <h2 style={styles.subheading}>Repository Integrations</h2>
      {renderIntegrationsTable()}
      <h2 style={styles.subheading}>Code Repositories</h2>
      {renderRepositoriesTable()}
      {renderOrgSelector()}
    </div>
  );
};

const styles = {
  container: {
    padding: '30px',
    backgroundColor: 'white',
    borderRadius: '0',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  heading: {
    color: '#0052CC',
    fontSize: '24px',
    marginBottom: '20px',
  },
  subheading: {
    marginTop: '30px',
    fontSize: '20px',
    color: '#172B4D',
  },
  githubAuth: {
    marginTop: '20px',
    marginBottom: '30px',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0',
    marginTop: '20px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  },
  th: {
    textAlign: 'left',
    padding: '16px',
    borderBottom: '2px solid #DFE1E6',
    backgroundColor: '#F4F5F7',
    color: '#172B4D',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  td: {
    padding: '16px',
    borderBottom: '1px solid #DFE1E6',
  },
  select: {
    width: '100%',
    padding: '8px',
    border: '1px solid #DFE1E6',
    borderRadius: '3px',
  },
  button: {
    padding: '8px 16px',
    backgroundColor: '#0052CC',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
    fontSize: '14px',
    marginRight: '10px',
    transition: 'background-color 0.3s ease',
  },
  keyframes: `
    @keyframes spin {
      from { transform: rotate(0deg); }
      to { transform: rotate(360deg); }
    }
  `,
  orgSelector: {
    padding: '20px',
    backgroundColor: '#F4F5F7',
    borderRadius: '4px',
    marginTop: '20px',
  },
  orgList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  orgButton: {
    padding: '8px 16px',
    backgroundColor: 'white',
    border: '1px solid #DFE1E6',
    borderRadius: '3px',
    cursor: 'pointer',
    textAlign: 'left',
  },
};

export default Config;
