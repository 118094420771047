import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MerlinAnalysis from './components/MerlinAnalysis';
import Config from './components/Config';
import GitHubAuthPage from './components/GitHubAuthPage';

const App = () => (
  <Router>
    <Routes>
      <Route path="/issue-panel" element={<MerlinAnalysis />} />
      <Route path="/config" element={<Config />} />
      <Route path="/github-auth" element={<GitHubAuthPage />} />
    </Routes>
  </Router>
);

const root = ReactDOM.createRoot(document.getElementById('root'));

// Wrap the rendering in AP.context.getContext
if (window.AP) {
  window.AP.context.getContext(() => {
    root.render(<App />);
  });
} else {
  // Fallback for local development
  root.render(<App />);
}
