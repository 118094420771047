import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import 'react-diff-view/style/index.css';
import { Diff, Hunk, parseDiff } from 'react-diff-view';

const MerlinAnalysis = () => {
  const [ap, setAp] = useState(null);
  const [issueId, setIssueId] = useState('');
  const [selectedProject, setSelectedProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [analysis, setAnalysis] = useState(null);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [isFullView, setIsFullView] = useState(false);
  const [isPostingQuestions, setIsPostingQuestions] = useState(false);
  const [questionsPosted, setQuestionsPosted] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Reviewing Issue...");

  useEffect(() => {
    if (isLoading) {
      setLoadingMessage("Reviewing Issue...");
      
      const messages = [
        { text: "Analyzing Code...", delay: 10000 },
        { text: "Compiling Findings...", delay: 20000 },
        { text: "Completing Analysis...", delay: 30000 }
      ];

      const timeouts = messages.map(({ text, delay }) =>
        setTimeout(() => setLoadingMessage(text), delay)
      );

      // Cleanup timeouts when component unmounts or loading completes
      return () => timeouts.forEach(timeout => clearTimeout(timeout));
    }
  }, [isLoading]);

  useEffect(() => {
    const initializeAP = async () => {
      if (window.AP) {
        try {
          await new Promise((resolve) => window.AP.context.getContext(resolve));
          setAp(window.AP);
          setIsLoading(false);
        } catch (error) {
          console.error('Error initializing AP:', error);
          setError('Error initializing Atlassian Connect. Please refresh the page.');
          setIsLoading(false);
        }
      } else {
        console.error('AP is not available');
        setError('Atlassian Connect is not available. Please check your installation.');
        setIsLoading(false);
      }
    };

    initializeAP();
  }, []);

  useEffect(() => {
    if (ap) {
      fetchIssueContext();
    }
  }, [ap]);

  useEffect(() => {
    if (ap) {
      ap.resize();
    }
  }, [ap, analysis]);

  useEffect(() => {
    if (!ap) return;

    // Create the observer
    const resizeObserver = new ResizeObserver(entries => {
      ap.resize();
    });

    // Function to observe the selected tab panel
    const observeSelectedPanel = () => {
      const tabPanel = document.querySelector('.react-tabs__tab-panel--selected');
      if (tabPanel) {
        resizeObserver.observe(tabPanel);
      }
    };

    // Observe initially
    observeSelectedPanel();

    // Also observe when tabs change
    const handleTabChange = () => {
      resizeObserver.disconnect();
      observeSelectedPanel();
    };

    document.addEventListener('react-tabs-selected', handleTabChange);

    // Cleanup
    return () => {
      resizeObserver.disconnect();
      document.removeEventListener('react-tabs-selected', handleTabChange);
    };
  }, [ap]);

  const fetchIssueContext = async () => {
    if (!ap || !ap.context) {
      setError('AP context is not available. Please try again later.');
      return;
    }

    try {
      const context = await ap.context.getContext();
      const issueKey = context.jira.issue.key;
      const projectId = context.jira.project.id;

      setIssueId(issueKey);
      setSelectedProject({ id: projectId, name: projectId });
      console.log('projectId', projectId);
      console.log('issueKey', issueKey);
      fetchAnalysis(projectId, issueKey);
    } catch (error) {
      console.error('Error fetching issue context:', error);
      setError('Failed to load issue context. Please try again later.');
    }
  };

  const fetchAnalysis = async (projectId, issueKey) => {
    setIsLoading(true);
    setAnalysis(null);
    setError(null);
    try {
      console.log("Fetching analysis for:", projectId, issueKey);
      const token = await ap.context.getToken();
      const response = await fetch(`/get-analysis?projectId=${projectId}&issueId=${issueKey}`, {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${token}`,
          'Accept': 'application/json'
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Analysis result:", result);
      setAnalysis(result.analysis);
    } catch (error) {
      console.error('Error fetching analysis:', error);
      if (error.message.includes('404')) {
        setError(`Issue ${issueKey} not found. Please check the issue ID and try again.`);
      } else {
        setError('An unexpected error occurred. Please try again later.');
      }
    } finally {
      setIsLoading(false);
      if (ap) ap.resize();
    }
  };

  const handleFeedbackSubmit = (e) => {
    e.preventDefault();
    console.log('Feedback submitted:', { rating, comment });
    alert('Thank you for your feedback!');
    setRating(0);
    setComment('');
  };

  const handleAcceptSolution = async () => {
    setIsAccepting(true);
    try {
      const result = await invoke('accept-solution', { projectId: selectedProject.id, issueId: issueId });
      alert(result.message);
    } catch (error) {
      console.error('Error accepting solution:', error);
      alert('Failed to accept solution. Please try again later.');
    } finally {
      setIsAccepting(false);
    }
  };

  const openDetailedView = () => {
    ap.dialog.create({
      key: 'detailedMerlinAnalysis',
      chrome: false,
      width: '80%',
      height: '80%',
      url: `/issue-panel?fullView=true&issueId=${issueId}&projectId=${selectedProject.id}`,
    });
  };

  const handleTabSelect = (index) => {
    if (ap) {
      ap.resize();
      setTimeout(() => ap.resize(), 100);
      setTimeout(() => ap.resize(), 500);
      document.dispatchEvent(new Event('react-tabs-selected'));
    }
  };

  const handlePostQuestions = async () => {
    if (!analysis?.additionalQuestions || !ap || questionsPosted) {
      return;
    }

    setIsPostingQuestions(true);
    try {
      const token = await ap.context.getToken();
      const context = await ap.context.getContext();
      const issueKey = context.jira.issue.key;

      const questionsText = `*Clarifying Questions from CodeMerlin:*\n${analysis.additionalQuestions
        .map(q => `• ${q}`)
        .join('\n')}`;

      const response = await fetch(`/post-comment?issueId=${issueKey}`, {
        method: 'POST',
        headers: {
          'Authorization': `JWT ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ comment: questionsText })
      });

      if (!response.ok) {
        throw new Error('Failed to post comment');
      }

      ap.flag.success({ body: 'Questions posted as a comment!' });
      setQuestionsPosted(true);
      
      ap.refreshIssuePage();
    } catch (error) {
      console.error('Error posting questions:', error);
      ap.flag.error({ body: 'Failed to post questions. Please try again.' });
    } finally {
      setIsPostingQuestions(false);
    }
  };

  const styles = {
    container: {
      padding: '20px',
      backgroundColor: 'white',
      borderRadius: '3px',
      boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
      fontFamily: "'Roboto', sans-serif",
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
    },
    logo: {
      width: '40px',
      height: '40px',
      marginRight: '15px',
    },
    heading: {
      color: '#0052CC',
      fontSize: '24px',
      margin: 0,
      fontFamily: "'Roboto', sans-serif",
      fontWeight: 500,
    },
    tabContent: {
      padding: '20px',
      backgroundColor: 'white',
      border: '1px solid #ddd',
      borderTop: 'none',
    },
    sectionTitle: {
      color: '#172B4D',
      fontSize: '18px',
      marginTop: '20px',
      marginBottom: '10px',
    },
    paragraph: {
      lineHeight: '1.5',
      marginBottom: '15px',
    },
    list: {
      paddingLeft: '20px',
      marginBottom: '15px',
    },
    listItem: {
      marginBottom: '10px',
      lineHeight: '1.5',
    },
    codeBlock: {
      marginBottom: '20px',
      border: '1px solid #ddd',
      borderRadius: '3px',
    },
    codeTitle: {
      backgroundColor: '#f4f5f7',
      padding: '8px 12px',
      borderBottom: '1px solid #ddd',
      fontWeight: 'bold',
    },
    code: {
      backgroundColor: '#f8f9fa',
      padding: '12px',
      overflowX: 'auto',
      fontFamily: "'Roboto Mono', monospace",
      fontSize: '14px',
      lineHeight: '1.4',
    },
    button: {
      padding: '8px 16px',
      backgroundColor: '#0052CC',
      color: 'white',
      border: 'none',
      borderRadius: '3px',
      cursor: 'pointer',
      fontSize: '14px',
      marginTop: '10px',
    },
    diffContainer: {
      margin: '10px 0',
      border: '1px solid #ddd',
      borderRadius: '3px',
    },
    diffWrapper: {
      fontSize: '14px',
      fontFamily: "'Roboto Mono', monospace",
      backgroundColor: '#fff',
      border: '1px solid #ddd',
      borderRadius: '3px',
      overflow: 'auto',
    },
  };

  const CodeDiff = ({ oldCode, newCode }) => {
    try {
      // Split both code blocks into lines and clean them
      const oldLines = oldCode.split('\n');
      const newLines = newCode.split('\n');
      
      // Find the actual differences
      let diffLines = [];
      let maxLength = Math.max(oldLines.length, newLines.length);
      
      for (let i = 0; i < maxLength; i++) {
        const oldLine = oldLines[i] || '';
        const newLine = newLines[i] || '';
        
        if (oldLine !== newLine) {
          if (oldLine) diffLines.push(`-${oldLine}`);
          if (newLine) diffLines.push(`+${newLine}`);
        } else {
          diffLines.push(` ${oldLine}`); // Unchanged lines
        }
      }

      // Create the diff text with only the changes
      const diffText = `diff --git a/file.js b/file.js
--- a/file.js
+++ b/file.js
@@ -1,${oldLines.length} +1,${newLines.length} @@
${diffLines.join('\n')}`;

      const files = parseDiff(diffText);

      return (
        <div style={{ 
          fontSize: '14px', 
          fontFamily: 'monospace',
          border: '1px solid #ddd',
          borderRadius: '4px',
          overflow: 'auto'
        }}>
          {files.map((file, i) => (
            <Diff 
              key={i}
              viewType="unified"
              diffType={file.type}
              hunks={file.hunks}
              gutterType="anchor"
              className="diff-view"
            >
              {hunks => hunks.map(hunk => (
                <Hunk 
                  key={hunk.content}
                  hunk={hunk}
                  gutterEvents={true}
                />
              ))}
            </Diff>
          ))}
        </div>
      );
    } catch (error) {
      console.error('Error in CodeDiff:', error);
      return <div>Error displaying diff</div>;
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.headerContainer}>
        <img src="/images/codemerlinlogo.png" alt="CodeMerlin Logo" style={styles.logo} />
        <h1 style={styles.heading}>CodeMerlin Analysis</h1>
      </div>
      {isLoading ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          {loadingMessage}
        </div>
      ) : error ? (
        <div style={{color: 'red'}}>{error}</div>
      ) : analysis ? (
        <Tabs onSelect={handleTabSelect}>
          <TabList>
            <Tab>Overview</Tab>
            <Tab>Solution & Changes</Tab>
            <Tab>Workaround</Tab>
            <Tab>Recommendations</Tab>
            {analysis?.additionalQuestions?.length > 0 && <Tab>Open Questions</Tab>}
            <Tab>Feedback</Tab>
          </TabList>

          <TabPanel style={styles.tabContent}>
            <h2 style={styles.sectionTitle}>Original Issue Information</h2>
            <p><strong>Repository:</strong> <a href={analysis.repository} target="_blank" rel="noopener noreferrer">{analysis.repositories}</a></p>
            <p><strong>Issue:</strong> <a href={analysis.issueLink} target="_blank" rel="noopener noreferrer">{analysis.issueTitle} (#{analysis.issueNumber})</a></p>
            <h2 style={styles.sectionTitle}>Root Cause</h2>
            <p style={styles.paragraph}>{analysis.rootCause}</p>
          </TabPanel>

          <TabPanel style={styles.tabContent}>
            <h2 style={styles.sectionTitle}>Solution</h2>
            <p style={styles.paragraph}>{analysis.solution}</p>
            
            <h2 style={styles.sectionTitle}>Code Changes</h2>
            {analysis.codeChanges?.map((change, index) => (
              <div key={index} style={styles.codeBlock}>
                <h3 style={styles.codeTitle}>{change.file} ({change.repository})</h3>
                <p><strong>Description:</strong> {change.description}</p>
                <div style={styles.diffContainer}>
                  <CodeDiff
                    oldCode={change.currentCode || ''}
                    newCode={change.suggestedCode || ''}
                  />
                </div>
              </div>
            ))}
            <h3 style={styles.sectionTitle}>Explanation of Changes</h3>
            <ul style={styles.list}>
              {analysis.explanation?.map((item, index) => (
                <li key={index} style={styles.listItem}>{item}</li>
              ))}
            </ul>
          </TabPanel>

          <TabPanel style={styles.tabContent}>
            {analysis.potentialWorkaround ? (
              <>
                <h2 style={styles.sectionTitle}>Potential Workaround</h2>
                <p style={styles.paragraph}><strong>Description:</strong> {analysis.potentialWorkaround.description}</p>
                <h3 style={styles.sectionTitle}>Steps:</h3>
                <ol style={styles.list}>
                  {analysis.potentialWorkaround.steps.map((step, index) => (
                    <li key={index} style={styles.listItem}>{step}</li>
                  ))}
                </ol>
                <h3 style={styles.sectionTitle}>Limitations:</h3>
                <p style={styles.paragraph}>{analysis.potentialWorkaround.limitations}</p>
              </>
            ) : (
              <p>No workaround available for this issue.</p>
            )}
          </TabPanel>

          <TabPanel style={styles.tabContent}>
            <h2 style={styles.sectionTitle}>Additional Recommendations</h2>
            <ul style={styles.list}>
              {analysis.recommendations?.map((item, index) => (
                <li key={index} style={styles.listItem}>{item}</li>
              ))}
            </ul>
            <h2 style={styles.sectionTitle}>Next Steps</h2>
            <ol style={styles.list}>
              {analysis.nextSteps?.map((item, index) => (
                <li key={index} style={styles.listItem}>{item}</li>
              ))}
            </ol>
          </TabPanel>

          <TabPanel style={styles.tabContent}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2 style={styles.sectionTitle}>Open Questions</h2>
              {!questionsPosted ? (
                <button
                  onClick={handlePostQuestions}
                  disabled={isPostingQuestions}
                  style={{
                    ...styles.button,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px'
                  }}
                >
                  {isPostingQuestions ? 'Posting...' : 'Post Questions to Comments'}
                </button>
              ) : (
                <span style={{ color: '#666', fontSize: '14px' }}>
                  ✓ Questions posted to comments
                </span>
              )}
            </div>
            {analysis.additionalQuestions?.length > 0 && (
              <ul style={styles.list}>
                {analysis.additionalQuestions?.map((question, index) => (
                  <li key={index} style={styles.listItem}>{question}</li>
                ))}
              </ul>
            )}
          </TabPanel>

          <TabPanel style={styles.tabContent}>
            <h2 style={styles.sectionTitle}>Feedback</h2>
            <form onSubmit={handleFeedbackSubmit}>
              <div>
                {[1, 2, 3, 4, 5].map((star) => (
                  <span
                    key={star}
                    onClick={() => setRating(star)}
                    style={{
                      cursor: 'pointer',
                      color: star <= rating ? 'gold' : 'gray',
                      fontSize: '24px',
                      marginRight: '5px',
                    }}
                  >
                    ★
                  </span>
                ))}
              </div>
              <textarea
                style={{
                  width: '100%',
                  padding: '8px',
                  marginTop: '10px',
                  borderRadius: '3px',
                  border: '1px solid #ddd',
                }}
                placeholder="Leave a comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <button type="submit" style={styles.button}>Submit Feedback</button>
            </form>
          </TabPanel>
        </Tabs>
      ) : (
        <div>No analysis available.</div>
      )}
    </div>
  );
};

export default MerlinAnalysis;
