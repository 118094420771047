import React, { useState, useEffect } from 'react';

const GitHubAuthPage = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [ap, setAp] = useState(null);

  useEffect(() => {
    if (window.AP) {
      setAp(window.AP);
    }
  }, []);

  const handleGitHubAuth = async () => {
    setIsAuthenticating(true);
    try {
      const response = await ap.request('/github-auth-url');
      const { authUrl } = response;
      
      // Open the auth URL in a new window
      const authWindow = window.open(authUrl, 'GitHub Auth', 'width=600,height=600');

      // Poll for the window to close
      const pollTimer = setInterval(async () => {
        if (authWindow.closed) {
          clearInterval(pollTimer);
          // Check if authentication was successful
          const result = await ap.request('/github-auth-callback');
          if (result.success) {
            setIsAuthenticated(true);
          }
        }
      }, 500);
    } catch (error) {
      console.error('Error during GitHub auth:', error);
    } finally {
      setIsAuthenticating(false);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Authenticate with GitHub</h1>
      {!isAuthenticated ? (
        <button onClick={handleGitHubAuth} disabled={isAuthenticating}>
          {isAuthenticating ? 'Authenticating...' : 'Start GitHub Authentication'}
        </button>
      ) : (
        <p>Successfully authenticated with GitHub!</p>
      )}
    </div>
  );
};

export default GitHubAuthPage;
